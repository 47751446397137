.stopwatch {
  display: flex;
  justify-content: center;
}

.stopwatchbutton {
  margin-left: 20px;
  color: white;
  background-color: teal;
  font-size: 0.7em;
  padding: 0.2em 0.5em;
  text-transform: uppercase;
  font-weight: bold;
}
