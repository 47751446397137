.App {
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: monospace;
  font-size: 48px;
  font-weight: 700;
  height: 100dvh;
  background-color: black;
}

.skuggslembg {
  background-color: #410f5a;
}

.heading {
  text-align: center;
  margin: 0.1em;
}

.list {
  list-style: none;
}

.listitem {
}
